import React from "react";
import logo from "./assets/images/logo.png";
import "./assets/css/App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>cool dude site, coming soon.</p>
      </header>
    </div>
  );
}

export default App;
